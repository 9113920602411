import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PowerballData } from './powerball-data';
import * as _ from 'lodash';

export interface IWinningsResponse {
  draw_date: string;
  winning_numbers: string;
  multiplier: string;
}

export interface IParsedWinningsResponse {
  date: string;
  numbers: any[];
  multiplier: string;
}

@Injectable()
export class PowerballService {
  fromDate: Date = new Date('2019-01-04T00:00:00');
  powerballData: any;

  constructor(private httpClient: HttpClient) {}

  /**
   * Http request to pull latest data
   */
  // async fetchPowerballResults() {
  //   try {
  //     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //     const url = 'http://data.ny.gov/resource/d6yy-54nr.json';
  //     const data = await this.httpClient
  //       .get(url, { headers: headers })
  //       .toPromise();

  //     this.powerballData = localStorage.getItem('powerball_data')
  //       ? JSON.parse(localStorage.getItem('powerball_data'))
  //       : null;

  //     if (!this.powerballData) {
  //       localStorage.setItem('powerball_data', JSON.stringify(data));
  //     }

  //     return await this.generatePowerballPlay(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  async generatePowerballPlay(data = null) {
    this.powerballData = PowerballData;

    const filtered = this.powerballData.filter(el => {
      const drawDate = new Date(el.draw_date);

      if (drawDate >= this.fromDate) {
        return el;
      }
    });

    const formattedData = filtered.map(result => ({
      date: result.draw_date,
      numbers: result.winning_numbers.split(' '),
      multiplier: result.multiplier
    }));

    const parsedsets = await this.parseWinningNumbers(formattedData);
    const filteredParsedSets = await this.filterParsedNumberSets(parsedsets);

    const ticket = filteredParsedSets.map(set => {
      const numbers = set.numbers;
      const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];

      return Object.assign({}, { key: set.key, number: randomNumber });
    });

    const sortedTicket = ticket
      .map(t => {
        if (t.key !== 'powerball') {
          return t.number;
        }
      })
      .join(',')
      .split(',');

    sortedTicket[5] = ticket[5].number;

    return sortedTicket;
  }

  private async parseWinningNumbers(results) {
    const plays = results.map(set => set.numbers);

    return plays.map((set: any[]) =>
      Object.assign(
        {},
        {
          first: set[0],
          second: set[1],
          third: set[2],
          fourth: set[3],
          fifth: set[4],
          powerball: set[5]
        }
      )
    );
  }

  private async filterParsedNumberSets(numberSets: any = []) {
    const firsts = [];
    const seconds = [];
    const thirds = [];
    const fourths = [];
    const fifths = [];
    const powerballs = [];

    const completeSets = [];
    numberSets.forEach((set: any) => {
      firsts.push(parseInt(set.first));
      seconds.push(parseInt(set.second));
      thirds.push(parseInt(set.third));
      fourths.push(parseInt(set.fourth));
      fifths.push(parseInt(set.fifth));
      powerballs.push(parseInt(set.powerball));

      completeSets.push(
        Object.assign(
          {},
          {
            twoThree: [set.second, set.third],
            fourthFifth: [set.fourth, set.fifth]
          }
        )
      );
    });

    const parsedNumberSets = {
      first: firsts.sort((a, b) => a - b),
      second: seconds.sort((a, b) => a - b),
      third: thirds.sort((a, b) => a - b),
      fourth: fourths.sort((a, b) => a - b),
      fifth: fifths.sort((a, b) => a - b),
      powerball: powerballs.sort((a, b) => a - b)
    };

    // const parsedNumberSets = {
    //   first: this.shuffle(firsts),
    //   second: this.shuffle(seconds),
    //   third: this.shuffle(thirds),
    //   fourth: this.shuffle(fourths),
    //   fifth: this.shuffle(fifths),
    //   powerball: this.shuffle(powerballs)
    // };

    const filteredNumbers = [];

    for (const key in parsedNumberSets) {
      if (parsedNumberSets.hasOwnProperty(key)) {
        let result = [];
        switch (key) {
          case 'powerball':
            //result = this.findDuplicates(parsedNumberSets[key], 5);
            result = ['01','03','02','04','05','06','07', '18', '8', '14', '15', '21', '23', '24','02','25','26'];
            // result = ['02','14','23', '04','18','25','11','21','24'];
            // result = ['4','7','18','8','14','24','25','26','5','23'];
            console.log(result);
            break;
          case 'first':
            const first = this.filterNumbersByRange(
              parsedNumberSets[key],
              1
            );

            // result = this.findDuplicates(first, 7);
            result = ['10','01','03','14','04','02','10','01', '02']
            console.log('1st: ', result);
            break;
          case 'second':
            const second = this.filterNumbersByRange(
              parsedNumberSets[key],
              5
            );

            // result = this.findDuplicates(second, 6);
            result = ['12','11','27','15','12','25','24']
            console.log('2nd: ', result);
            break;
          case 'third':
            const third = this.filterNumbersByRange(
              parsedNumberSets[key],
              9
            );

            const dups = this.findDuplicates(third, 4);

            // result = this.filterNumbersByRange(dups, 10, 36);
            result = ['24','34','36','20','29','21','34']
            // result = ['12']
            console.log('3rd: ', result);
            break;
          case 'fourth':
            const fourth = this.filterNumbersByRange(
              parsedNumberSets[key],
              27
            );

            // result = this.findDuplicates(fourth, 6);
            result = ['44','53','45','67','54','55','66','61']
            console.log('4th: ', result);
            break;
          case 'fifth':
            const fifth = this.filterNumbersByRange(
              parsedNumberSets[key],
              44
            );

            // result = this.findDuplicates(fifth, 6);
            result = ['53','67','64','69','67']
            console.log('5th: ', result);
            break;
        }

        filteredNumbers.push(Object.assign({}, { key: key, numbers: this.shuffle(result) }));
      }
    }

    return filteredNumbers;
  }

  async getRecentDrawings(count: number) {
    const recentDraws = [];

    for (let i = 0; i < this.powerballData.length; i++) {
      recentDraws.push(this.powerballData[i]);
    }

    return recentDraws.map(result => ({
      date: result.draw_date,
      numbers: result.winning_numbers.split(' '),
      multiplier: result.multiplier
    }));
  }

  private filterNumbersByRange(set: any[], from: number, to: number = null) {
    const fromSet = set.filter(number => {
      return number >= from;
    });

    if (to) {
      const range = fromSet.filter(number => {
        return number <= to;
      });

      return range;
    }

    return fromSet;
  }

  private findDuplicates(array: any[], num: number) {
    return _.transform(
      _.countBy(array),
      (result, count, value) => {
        if (count >= num) {
          result.push(value);
        }
      },
      []
    );
  }

  private  shuffle(array) {
    const newArray = [...array]
    const length = newArray.length

    for (let start = 0; start < length; start++) {
      const randomPosition = Math.floor((newArray.length - start) * Math.random())
      const randomItem = newArray.splice(randomPosition, 1)

      newArray.push(...randomItem)
    }

    return newArray
  }
}
