import { Component } from '@angular/core';
import { PowerballService } from './powerball.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';
import { find, isEmpty } from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Powerball Ticket Generator';
  play: any[];
  history: any[] = [];
  year = new Date().getFullYear();
  totalMatches: number;

  recentDrawings;
  matchingSets: any[] = [];

  constructor (
    private powerballService: PowerballService,
    private clipboardService: ClipboardService,
    private toastr: ToastrService,
    private lightbox: Lightbox,
  ) {}

  async generateTicket() {
    const generatePowerballPlayResults = await this.powerballService.generatePowerballPlay();
    const pastDrawingCount = 1000;
    const recentDrawings = await this.powerballService.getRecentDrawings(pastDrawingCount);

    const thisPlay = generatePowerballPlayResults.map(num => {
      if (num.length === 1) {
        return `0${num}`;
      }

      return num;
    });

    const thisPlayPB = thisPlay[5];

    thisPlay.pop();

    this.play = thisPlay.sort(((a, b) => parseInt(a) - parseInt(b)));

    this.play.push(thisPlayPB);

    const matchedSets = [];

    this.recentDrawings = recentDrawings.map((set, i) => {
      const numbers = set.numbers;
      const numberMatches = [];

      numbers.forEach((num, i) => {
        if (this.play[i] === num) {
          numberMatches.push('match');
        }
      });

      if (!isEmpty(numberMatches) && numberMatches.length >= 3) {
        matchedSets.push({ index: i });
      }

      return set.numbers;
    });

    this.recentDrawings = this.recentDrawings.filter((set, i) => {
      const result = find(matchedSets, match => match.index === i);

      if (result) {
        return set;
      }
    });

    this.totalMatches = matchedSets.length;

    this.history.push(this.play);

    this.toastr.success('', 'Generated Powerball Play', {
      timeOut: 1500,
      positionClass: 'toast-bottom-right'
    });
  }

  copyTicketToClipboard() {
    let text = '';
    this.history.forEach(ticket => {
      text += ticket + '\n';
    });

    this.clipboardService.copyFromContent(text);

    this.toastr.success('', 'Copied to clipboard', {
      timeOut: 1500,
      positionClass: 'toast-bottom-right'
    });
  }

  open(): void {
    const image = ['../assets/winnings_chart.png'];

    this.lightbox.open([{
      src: '../assets/winnings_chart.png',
      caption: '',
      thumb: '',
    }], 0);
  }

  close(): void {
    this.lightbox.close();
  }
}
