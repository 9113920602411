export const PowerballData = [
  {
    draw_date: "2023-11-22T00:00:00.000",
    winning_numbers: "20 24 33 39 42 21",
    multiplier: "2",
  },
  {
    draw_date: "2023-11-20T00:00:00.000",
    winning_numbers: "19 26 30 39 63 13",
    multiplier: "3",
  },
  {
    draw_date: "2023-11-15T00:00:00.000",
    winning_numbers: "03 04 51 53 60 06",
    multiplier: "3",
  },
  {
    draw_date: "2023-11-13T00:00:00.000",
    winning_numbers: "24 33 35 37 42 21",
    multiplier: "2",
  },
  {
    draw_date: "2023-11-11T00:00:00.000",
    winning_numbers: "01 12 14 24 57 07",
    multiplier: "4",
  },
  {
    draw_date: "2023-11-08T00:00:00.000",
    winning_numbers: "14 21 33 39 62 20",
    multiplier: "2",
  },
  {
    draw_date: "2023-11-06T00:00:00.000",
    winning_numbers: "12 25 40 59 61 26",
    multiplier: "2",
  },
  {
    draw_date: "2023-11-04T00:00:00.000",
    winning_numbers: "01 28 30 34 52 06",
    multiplier: "2",
  },
  {
    draw_date: "2023-11-01T00:00:00.000",
    winning_numbers: "22 26 39 47 63 12",
    multiplier: "3",
  },
  {
    draw_date: "2023-10-30T00:00:00.000",
    winning_numbers: "19 22 34 66 69 05",
    multiplier: "3",
  },
  {
    draw_date: "2023-10-28T00:00:00.000",
    winning_numbers: "14 24 50 59 64 02",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-25T00:00:00.000",
    winning_numbers: "25 27 41 53 68 02",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-23T00:00:00.000",
    winning_numbers: "18 21 25 46 64 21",
    multiplier: "5",
  },
  {
    draw_date: "2023-10-21T00:00:00.000",
    winning_numbers: "06 15 24 67 68 11",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-18T00:00:00.000",
    winning_numbers: "01 04 13 35 58 24",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-16T00:00:00.000",
    winning_numbers: "02 27 31 44 64 18",
    multiplier: "3",
  },
  {
    draw_date: "2023-10-14T00:00:00.000",
    winning_numbers: "14 16 42 48 64 14",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-11T00:00:00.000",
    winning_numbers: "22 24 40 52 64 10",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-09T00:00:00.000",
    winning_numbers: "16 34 46 55 67 14",
    multiplier: "3",
  },
  {
    draw_date: "2023-10-07T00:00:00.000",
    winning_numbers: "47 54 57 60 65 19",
    multiplier: "3",
  },
  {
    draw_date: "2023-10-04T00:00:00.000",
    winning_numbers: "09 35 54 63 64 01",
    multiplier: "2",
  },
  {
    draw_date: "2023-10-02T00:00:00.000",
    winning_numbers: "12 26 27 43 47 05",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-30T00:00:00.000",
    winning_numbers: "19 30 37 44 46 22",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-27T00:00:00.000",
    winning_numbers: "01 07 46 47 63 07",
    multiplier: "3",
  },
  {
    draw_date: "2023-09-25T00:00:00.000",
    winning_numbers: "10 12 22 36 50 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-23T00:00:00.000",
    winning_numbers: "01 12 20 33 66 21",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-20T00:00:00.000",
    winning_numbers: "16 27 59 62 63 23",
    multiplier: "3",
  },
  {
    draw_date: "2023-09-18T00:00:00.000",
    winning_numbers: "02 21 26 40 42 09",
    multiplier: "3",
  },
  {
    draw_date: "2023-09-16T00:00:00.000",
    winning_numbers: "08 11 19 24 46 05",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-13T00:00:00.000",
    winning_numbers: "22 30 37 44 45 18",
    multiplier: "3",
  },
  {
    draw_date: "2023-09-11T00:00:00.000",
    winning_numbers: "09 25 27 53 66 05",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-09T00:00:00.000",
    winning_numbers: "11 19 29 63 68 25",
    multiplier: "2",
  },
  {
    draw_date: "2023-09-06T00:00:00.000",
    winning_numbers: "09 14 20 23 63 01",
    multiplier: "3",
  },
  {
    draw_date: "2023-09-04T00:00:00.000",
    winning_numbers: "01 26 32 46 51 13",
    multiplier: "3",
  },
  {
    draw_date: "2023-09-02T00:00:00.000",
    winning_numbers: "25 38 42 66 67 19",
    multiplier: "4",
  },
  {
    draw_date: "2023-08-30T00:00:00.000",
    winning_numbers: "04 13 35 61 69 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-28T00:00:00.000",
    winning_numbers: "04 06 25 55 68 26",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-26T00:00:00.000",
    winning_numbers: "20 22 26 28 63 05",
    multiplier: "3",
  },
  {
    draw_date: "2023-08-23T00:00:00.000",
    winning_numbers: "25 30 32 33 55 20",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-21T00:00:00.000",
    winning_numbers: "03 04 12 22 28 16",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-19T00:00:00.000",
    winning_numbers: "01 25 27 38 62 13",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-16T00:00:00.000",
    winning_numbers: "09 11 17 19 55 01",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-14T00:00:00.000",
    winning_numbers: "32 34 37 39 47 03",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-12T00:00:00.000",
    winning_numbers: "19 21 37 50 65 26",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-09T00:00:00.000",
    winning_numbers: "10 15 21 67 69 03",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-07T00:00:00.000",
    winning_numbers: "06 13 20 35 54 22",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-05T00:00:00.000",
    winning_numbers: "18 42 44 62 65 23",
    multiplier: "2",
  },
  {
    draw_date: "2023-08-02T00:00:00.000",
    winning_numbers: "23 24 33 51 64 05",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-31T00:00:00.000",
    winning_numbers: "02 11 48 58 65 13",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-29T00:00:00.000",
    winning_numbers: "10 25 27 34 38 02",
    multiplier: "3",
  },
  {
    draw_date: "2023-07-26T00:00:00.000",
    winning_numbers: "03 16 40 48 60 14",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-24T00:00:00.000",
    winning_numbers: "03 04 12 28 49 25",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-22T00:00:00.000",
    winning_numbers: "25 27 36 37 63 07",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-19T00:00:00.000",
    winning_numbers: "07 10 11 13 24 24",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-17T00:00:00.000",
    winning_numbers: "05 08 09 17 41 21",
    multiplier: "4",
  },
  {
    draw_date: "2023-07-15T00:00:00.000",
    winning_numbers: "02 09 43 55 57 18",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-12T00:00:00.000",
    winning_numbers: "23 35 45 66 67 20",
    multiplier: "3",
  },
  {
    draw_date: "2023-07-10T00:00:00.000",
    winning_numbers: "02 24 34 53 58 13",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-08T00:00:00.000",
    winning_numbers: "07 23 24 32 43 18",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-05T00:00:00.000",
    winning_numbers: "17 24 48 62 68 23",
    multiplier: "2",
  },
  {
    draw_date: "2023-07-03T00:00:00.000",
    winning_numbers: "15 26 31 38 61 03",
    multiplier: "3",
  },
  {
    draw_date: "2023-07-01T00:00:00.000",
    winning_numbers: "04 17 35 49 61 08",
    multiplier: "2",
  },
  {
    draw_date: "2023-06-28T00:00:00.000",
    winning_numbers: "19 25 34 57 68 04",
    multiplier: "5",
  },
  {
    draw_date: "2023-06-26T00:00:00.000",
    winning_numbers: "06 28 39 43 54 12",
    multiplier: "4",
  },
  {
    draw_date: "2023-06-24T00:00:00.000",
    winning_numbers: "02 38 44 50 62 19",
    multiplier: "3",
  },
  {
    draw_date: "2023-06-21T00:00:00.000",
    winning_numbers: "05 11 33 35 63 14",
    multiplier: "2",
  },
  {
    draw_date: "2023-06-19T00:00:00.000",
    winning_numbers: "36 39 52 57 69 01",
    multiplier: "3",
  },
  {
    draw_date: "2023-06-17T00:00:00.000",
    winning_numbers: "02 12 45 61 64 26",
    multiplier: "2",
  },
  {
    draw_date: "2023-06-14T00:00:00.000",
    winning_numbers: "03 20 36 42 64 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-06-12T00:00:00.000",
    winning_numbers: "02 03 16 23 68 07",
    multiplier: "2",
  },
  {
    draw_date: "2023-06-05T00:00:00.000",
    winning_numbers: "02 31 45 46 49 20",
    multiplier: "2",
  },
  {
    draw_date: "2023-06-03T00:00:00.000",
    winning_numbers: "15 45 64 67 68 18",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-31T00:00:00.000",
    winning_numbers: "02 04 54 61 62 14",
    multiplier: "3",
  },
  {
    draw_date: "2023-05-29T00:00:00.000",
    winning_numbers: "21 33 35 62 64 24",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-27T00:00:00.000",
    winning_numbers: "24 38 39 48 56 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-24T00:00:00.000",
    winning_numbers: "12 21 44 50 58 26",
    multiplier: "3",
  },
  {
    draw_date: "2023-05-22T00:00:00.000",
    winning_numbers: "09 38 48 52 68 25",
    multiplier: "4",
  },
  {
    draw_date: "2023-05-20T00:00:00.000",
    winning_numbers: "17 23 32 38 63 23",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-17T00:00:00.000",
    winning_numbers: "18 34 37 45 51 14",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-15T00:00:00.000",
    winning_numbers: "01 26 28 55 58 25",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-13T00:00:00.000",
    winning_numbers: "03 15 20 23 46 11",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-10T00:00:00.000",
    winning_numbers: "21 24 33 55 69 03",
    multiplier: "10",
  },
  {
    draw_date: "2023-05-08T00:00:00.000",
    winning_numbers: "15 20 33 36 43 12",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-06T00:00:00.000",
    winning_numbers: "31 39 47 51 53 06",
    multiplier: "2",
  },
  {
    draw_date: "2023-05-03T00:00:00.000",
    winning_numbers: "21 26 30 45 47 23",
    multiplier: "3",
  },
  {
    draw_date: "2023-05-01T00:00:00.000",
    winning_numbers: "03 38 55 61 66 01",
    multiplier: "3",
  },
  {
    draw_date: "2023-04-29T00:00:00.000",
    winning_numbers: "16 53 54 57 65 08",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-26T00:00:00.000",
    winning_numbers: "02 15 30 35 49 06",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-24T00:00:00.000",
    winning_numbers: "19 21 55 66 68 03",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-22T00:00:00.000",
    winning_numbers: "17 36 47 63 68 25",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-19T00:00:00.000",
    winning_numbers: "04 11 21 38 64 11",
    multiplier: "3",
  },
  {
    draw_date: "2023-04-17T00:00:00.000",
    winning_numbers: "23 25 35 63 64 25",
    multiplier: "3",
  },
  {
    draw_date: "2023-04-15T00:00:00.000",
    winning_numbers: "01 33 34 56 59 18",
    multiplier: "3",
  },
  {
    draw_date: "2023-04-12T00:00:00.000",
    winning_numbers: "09 36 41 44 59 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-10T00:00:00.000",
    winning_numbers: "09 10 36 46 52 14",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-08T00:00:00.000",
    winning_numbers: "11 22 24 51 60 18",
    multiplier: "3",
  },
  {
    draw_date: "2023-04-05T00:00:00.000",
    winning_numbers: "03 05 09 42 52 11",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-03T00:00:00.000",
    winning_numbers: "16 30 31 54 68 01",
    multiplier: "2",
  },
  {
    draw_date: "2023-04-01T00:00:00.000",
    winning_numbers: "11 19 21 29 52 17",
    multiplier: "4",
  },
  {
    draw_date: "2023-03-29T00:00:00.000",
    winning_numbers: "04 09 24 46 66 07",
    multiplier: "3",
  },
  {
    draw_date: "2023-03-27T00:00:00.000",
    winning_numbers: "19 26 36 43 58 14",
    multiplier: "3",
  },
  {
    draw_date: "2023-03-25T00:00:00.000",
    winning_numbers: "15 17 18 47 57 19",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-22T00:00:00.000",
    winning_numbers: "27 28 37 50 57 05",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-20T00:00:00.000",
    winning_numbers: "01 27 32 47 67 14",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-18T00:00:00.000",
    winning_numbers: "14 20 30 54 69 11",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-15T00:00:00.000",
    winning_numbers: "16 18 33 37 50 08",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-13T00:00:00.000",
    winning_numbers: "03 10 24 46 63 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-11T00:00:00.000",
    winning_numbers: "11 20 33 43 58 24",
    multiplier: "2",
  },
  {
    draw_date: "2023-03-08T00:00:00.000",
    winning_numbers: "26 27 43 61 69 04",
    multiplier: "3",
  },
  {
    draw_date: "2023-03-06T00:00:00.000",
    winning_numbers: "02 13 29 58 69 04",
    multiplier: "3",
  },
  {
    draw_date: "2023-03-04T00:00:00.000",
    winning_numbers: "10 16 18 40 66 16",
    multiplier: "3",
  },
  {
    draw_date: "2023-03-01T00:00:00.000",
    winning_numbers: "02 09 28 36 53 04",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-27T00:00:00.000",
    winning_numbers: "16 28 49 51 55 23",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-25T00:00:00.000",
    winning_numbers: "11 24 58 66 67 26",
    multiplier: "3",
  },
  {
    draw_date: "2023-02-22T00:00:00.000",
    winning_numbers: "11 19 39 44 65 07",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-20T00:00:00.000",
    winning_numbers: "03 17 26 38 54 15",
    multiplier: "3",
  },
  {
    draw_date: "2023-02-18T00:00:00.000",
    winning_numbers: "08 21 31 32 37 23",
    multiplier: "4",
  },
  {
    draw_date: "2023-02-15T00:00:00.000",
    winning_numbers: "31 32 54 60 63 12",
    multiplier: "4",
  },
  {
    draw_date: "2023-02-13T00:00:00.000",
    winning_numbers: "17 26 37 61 65 02",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-11T00:00:00.000",
    winning_numbers: "10 23 30 54 65 11",
    multiplier: "4",
  },
  {
    draw_date: "2023-02-08T00:00:00.000",
    winning_numbers: "52 58 59 64 66 09",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-06T00:00:00.000",
    winning_numbers: "05 11 22 23 69 07",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-04T00:00:00.000",
    winning_numbers: "02 08 15 19 58 10",
    multiplier: "2",
  },
  {
    draw_date: "2023-02-01T00:00:00.000",
    winning_numbers: "31 43 58 59 66 09",
    multiplier: "2",
  },
  {
    draw_date: "2023-01-30T00:00:00.000",
    winning_numbers: "01 04 12 36 49 05",
    multiplier: "2",
  },
  {
    draw_date: "2023-01-28T00:00:00.000",
    winning_numbers: "02 18 23 27 47 15",
    multiplier: "4",
  },
  {
    draw_date: "2023-01-25T00:00:00.000",
    winning_numbers: "09 17 20 38 40 18",
    multiplier: "2",
  },
  {
    draw_date: "2023-01-23T00:00:00.000",
    winning_numbers: "12 31 47 58 60 23",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-21T00:00:00.000",
    winning_numbers: "05 14 19 46 64 22",
    multiplier: "4",
  },
  {
    draw_date: "2023-01-18T00:00:00.000",
    winning_numbers: "06 15 22 42 47 26",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-16T00:00:00.000",
    winning_numbers: "04 14 33 39 61 03",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-14T00:00:00.000",
    winning_numbers: "24 26 39 47 57 23",
    multiplier: "2",
  },
  {
    draw_date: "2023-01-11T00:00:00.000",
    winning_numbers: "04 08 46 47 48 05",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-09T00:00:00.000",
    winning_numbers: "18 43 48 60 69 14",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-07T00:00:00.000",
    winning_numbers: "35 36 44 45 67 14",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-04T00:00:00.000",
    winning_numbers: "12 32 56 67 68 26",
    multiplier: "3",
  },
  {
    draw_date: "2023-01-02T00:00:00.000",
    winning_numbers: "07 09 12 31 62 22",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-31T00:00:00.000",
    winning_numbers: "18 37 44 50 64 11",
    multiplier: "3",
  },
  {
    draw_date: "2022-12-28T00:00:00.000",
    winning_numbers: "26 32 38 45 56 01",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-26T00:00:00.000",
    winning_numbers: "17 41 47 60 61 17",
    multiplier: "3",
  },
  {
    draw_date: "2022-12-24T00:00:00.000",
    winning_numbers: "17 37 46 54 67 08",
    multiplier: "3",
  },
  {
    draw_date: "2022-12-21T00:00:00.000",
    winning_numbers: "12 15 24 34 59 14",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-19T00:00:00.000",
    winning_numbers: "07 37 55 65 67 12",
    multiplier: "5",
  },
  {
    draw_date: "2022-12-17T00:00:00.000",
    winning_numbers: "33 56 64 66 68 12",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-14T00:00:00.000",
    winning_numbers: "36 51 59 66 68 25",
    multiplier: "10",
  },
  {
    draw_date: "2022-12-12T00:00:00.000",
    winning_numbers: "16 31 50 55 61 09",
    multiplier: "4",
  },
  {
    draw_date: "2022-12-10T00:00:00.000",
    winning_numbers: "09 23 47 49 68 19",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-07T00:00:00.000",
    winning_numbers: "06 28 44 59 61 21",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-05T00:00:00.000",
    winning_numbers: "35 45 47 54 55 14",
    multiplier: "2",
  },
  {
    draw_date: "2022-12-03T00:00:00.000",
    winning_numbers: "06 13 33 36 37 07",
    multiplier: "4",
  },
  {
    draw_date: "2022-11-30T00:00:00.000",
    winning_numbers: "04 19 24 47 66 10",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-28T00:00:00.000",
    winning_numbers: "29 30 32 48 50 20",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-26T00:00:00.000",
    winning_numbers: "15 30 47 50 51 03",
    multiplier: "10",
  },
  {
    draw_date: "2022-11-23T00:00:00.000",
    winning_numbers: "01 02 31 39 66 25",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-21T00:00:00.000",
    winning_numbers: "01 06 40 51 67 02",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-19T00:00:00.000",
    winning_numbers: "07 28 62 63 64 10",
    multiplier: "3",
  },
  {
    draw_date: "2022-11-16T00:00:00.000",
    winning_numbers: "28 34 51 53 56 11",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-14T00:00:00.000",
    winning_numbers: "19 35 53 54 67 21",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-12T00:00:00.000",
    winning_numbers: "16 20 44 57 58 06",
    multiplier: "4",
  },
  {
    draw_date: "2022-11-09T00:00:00.000",
    winning_numbers: "07 14 24 30 56 07",
    multiplier: "2",
  },
  {
    draw_date: "2022-11-05T00:00:00.000",
    winning_numbers: "28 45 53 56 69 20",
    multiplier: "3",
  },
  {
    draw_date: "2022-11-02T00:00:00.000",
    winning_numbers: "02 11 22 35 60 23",
    multiplier: "2",
  },
  {
    draw_date: "2022-10-31T00:00:00.000",
    winning_numbers: "13 19 36 39 59 13",
    multiplier: "3",
  },
  {
    draw_date: "2022-10-29T00:00:00.000",
    winning_numbers: "19 31 40 46 57 23",
    multiplier: "3",
  },
  {
    draw_date: "2022-10-26T00:00:00.000",
    winning_numbers: "19 36 37 46 56 24",
    multiplier: "2",
  },
];
